<!-- 优惠券 -->
<template>
  <el-container class="Wrapper">
    <el-container class="container">
      <el-main class="">
        <div class="main main2">
          <div class="screening">
            <span class="type">
              优惠券类别
            </span>
            <span class="FilterDox">
              <el-select v-model="types" placeholder="">
                <el-option label="代金券" value="1" />
                <el-option label="满减券" value="2" />
                <el-option label="折扣券" value="3" />
                <el-option label="兑换券" value="4" />
              </el-select>
            </span>
            <span class="type">
              审核状态
            </span>
            <span class="FilterDox">
              <el-select v-model="type">
                <el-option label="待发放" value="5" />
                <el-option label="已发放 " value="6" />
                <el-option label="已下线 " value="7" />
              </el-select>
            </span>
            <span class="SearchDox">
              <span>
                <el-input class="inputbox" v-model="search" placeholder="请输入优惠卷名称或编号ID" />
              </span>
              <span>
                <el-button type="primary" @click="searchs">查询</el-button>
              </span>
            </span>
          </div>
          <!-- <div class="upper">
            <div class="handoff">
              <el-button @click="currentTaba">
                待发放
              </el-button>
              <el-button @click="currentTabb">
                已发放
              </el-button>
              <el-button @click="currentTabc">
                已下线
              </el-button>
            </div>
            <div class="line">
              <hr>
            </div>
          </div> -->
          <div class="main1">
            <div class="main_item1 " style="height: 100%;">
              <el-table :data="coupons" style="width: 100%;" height="75vh">
                <el-table-column prop="id" label="序号">
                  <template v-slot="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="id" label="券ID"></el-table-column>
                <el-table-column prop="name" label="优惠券名称" width="150px"></el-table-column>
                <el-table-column prop="" label="优惠类别">
                  <template #default="{ row }">
                    <span v-if="row.discountType === 2">满减券</span>
                    <span v-if="row.discountType === 1">代金券</span>
                    <span v-if="row.discountType === 3">折扣券</span>
                    <span v-if="row.discountType === 4">兑换券</span>
                  </template>
                </el-table-column>
                <el-table-column prop="description" label="描述" width="150px"></el-table-column>
                <el-table-column prop="" label="优惠金额">
                  <template #default="{ row }">
                    <span>{{ row.discountPrice }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="number" label="数量"></el-table-column>
                <el-table-column prop="createBy" label="创建人"></el-table-column>
                <el-table-column prop="validStartTime" label="开始时间" width="100px"></el-table-column>
                <el-table-column prop="validEndTime" label="结束时间" width="100px"></el-table-column>
                <el-table-column label="操作" width="200px">
                  <template #default="scope">
                    <el-button type="primary" @click="primary(scope.row.id)" :icon="Edit">编辑</el-button>
                    <el-button type="danger" @click="deleteCoupon(scope.row.id)" :icon="Delete">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="PopUps">
            <el-button type="primary" @click="toggleModala" class="appear">新增优惠券</el-button>
            <div v-if="showModal" class="modal">
              <div class="modal-content">
                <span class="close" @click="toggleModal">&times;</span>
                <span>创建优惠券</span>
                <el-form :model="form" label-width="120px">
                  <!-- <el-form-item label="优惠券ID">
                  <el-input class="inputbox" v-model="form.id" />
                </el-form-item> -->
                  <el-form-item label="优惠券名称">
                    <el-input class="inputbox" v-model="form.name" />
                  </el-form-item>
                  <!-- <el-form-item label="优惠券类型">
                  <el-select v-model="form.region" placeholder="please select your zone">
                    <el-option label="Zone one" value="shanghai" />
                    <el-option label="Zone two" value="beijing" />
                  </el-select>
                </el-form-item> -->
                  <el-form-item label="优惠券类别">
                    <el-radio-group v-model="form.category">
                      <el-radio label="代金券" />
                      <el-radio label="满减券" />
                      <el-radio label="兑换券" />
                      <el-radio label="折扣券" />
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="form.category !== '代金券'" label="使用门槛" @change="ass">
                    <el-radio-group v-model="form.doorsill">
                      <el-radio label="无门槛" />
                      <el-radio label="0" v-if="form.category !== '折扣券'">满<el-input-number v-model="form.doorsills"
                          @change="handleChange" :disabled="isDisableds" />元可用
                      </el-radio>
                      <el-radio label="1" v-if="form.category === '折扣券'">满<el-input class="inputbox"
                          v-model="form.doorsillsz" placeholder="请输入(为0时折扣无限制)" />元可用，折扣比例<el-input class="inputboxs"
                          v-model="form.doorsillae" />%，折扣上限<el-input class="inputboxs"
                          v-model="form.discountLimitPrice" /></el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="优惠券面值">
                    价值<el-input-number v-model="form.facevalues" :min="1" />元
                    <!-- <el-radio label="兑换" v-model="form.exchange">
                        <el-select v-model="form.as" placeholder="请选择兑换的金额">
                          <el-option label="2" value="2" />
                          <el-option label="1" value="1" />
                        </el-select>
                      </el-radio> -->
                  </el-form-item>
                  <el-form-item label="优惠券数量">
                    <el-input-number v-model="form.quantity" :min="1" />张
                  </el-form-item>
                  <el-form-item label="使用范围">
                    <el-radio-group v-model="form.type">
                      <el-radio label="门店" />
                      <el-radio label="商品" />
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="门店选择" v-if="form.type === '门店'">
                    <el-radio-group>
                      <el-select v-model="form.store" placeholder="请选择商品的门店">
                        <el-option v-for="item in store" :key="item.value" :label="item.name" :value="item.id" />
                      </el-select>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="有效期" class="time">
                    <el-radio label="固定时间" v-model="form.Fixedtime">
                      固定时间 <span class="Separated">日期范围</span><el-date-picker v-model="form.value2" type="datetimerange"
                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" align="right">
                      </el-date-picker>
                    </el-radio>
                    <!-- <el-radio label="a" v-model="form.Fixedtime">
                    领取后X天有效<span class="Separated">有效天数<el-input class="inputboxab" v-model="form.day"
                        placeholder="有效期至最后一天23：59：59" />天</span>
                  </el-radio> -->
                  </el-form-item>
                  <el-form-item label="发放类型">
                    <el-radio label="后台自动发放" v-model="form.days">
                    </el-radio>
                    <el-radio label="用户自主领取" v-model="form.days">
                    </el-radio>
                  </el-form-item>
                  <el-form-item label="可领取用户">
                    <el-radio label="全体用户可用" v-model="form.user">
                    </el-radio>
                    <el-radio label="全体会员可用" v-model="form.user">
                    </el-radio>
                    <el-radio label="全体铂金可用" v-model="form.user">
                    </el-radio>
                  </el-form-item>
                  <el-form-item label="优惠券描述">
                    <el-input class="inputboxsa" v-model="form.descriptions" placeholder="请描述优惠券" />
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit">确定</el-button>
                    <el-button @click="toggleModal">取消</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="PopUps">
            <div v-if="showModals" class="modal">
              <div class="modal-content">
                <span class="close" @click="toggleModals">&times;</span>
                <span>修改优惠券</span>
                <el-form :model="forms" label-width="120px">
                  <!-- <el-form-item label="优惠券ID">
                  <el-input class="inputbox" v-model="forms.ids" />
                </el-form-item> -->
                  <el-form-item label="优惠券名称">
                    <el-input class="inputbox" v-model="forms.names" />
                  </el-form-item>
                  <!-- <el-form-item label="优惠券类型">
                  <el-select v-model="forms.regions" placeholder="please select your zone">
                    <el-option label="Zone one" value="shanghai" />
                    <el-option label="Zone two" value="beijing" />
                  </el-select>
                </el-form-item> -->
                  <el-form-item label="优惠券类别">
                    <el-radio-group v-model="forms.categorys">
                      <el-radio label="代金券" value="1" />
                      <el-radio label="满减券" />
                      <el-radio label="兑换券" />
                      <el-radio label="折扣券" />
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="使用门槛" v-if="forms.categorys !== '代金券'">
                    <el-radio-group v-model="forms.doorsillsa" @change="as">
                      <el-radio label="无门槛" />
                      <el-radio label="0" v-if="forms.categorys !== '折扣券'">满<el-input-number v-model="forms.doorsillss"
                          :min="1" @change="handleChange" :disabled="isDisabled" />元可用
                      </el-radio>
                      <el-radio label="1" v-if="forms.categorys === '折扣券'">满<el-input class="inputbox"
                          v-model="forms.doorsillszs" placeholder="请输入(为0时折扣无限制)" />元可用，折扣比例<el-input class="inputboxs"
                          v-model="forms.doorsillas" />%</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="优惠券面值">
                    价值<el-input-number v-model="forms.facevaluess" :min="1" @change="handleChanges" />元
                    <!-- <el-radio label="兑换" v-model="forms.exchanges">
                        <el-select v-model="forms.ass" placeholder="请选择兑换的金额">
                          <el-option label="2" value="2" />
                          <el-option label="1" value="1" />
                        </el-select>
                      </el-radio> -->
                  </el-form-item>
                  <el-form-item label="优惠券数量">
                    <el-input-number v-model="forms.quantitys" :min="1" @change="handleChanges" />张
                  </el-form-item>
                  <el-form-item label="使用范围">
                    <el-radio-group v-model="forms.types">
                      <el-radio label="门店" />
                      <el-radio label="商品" />
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="有效期" class="time">
                    <el-radio label="固定时间" v-model="forms.Fixedtimes">
                      固定时间 <span class="Separated">日期范围</span><el-date-picker v-model="forms.value2s"
                        type="datetimerange" :picker-options="pickerOptions" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" align="right">
                      </el-date-picker>
                    </el-radio>
                  </el-form-item>
                  <el-form-item label="发放类型">
                    <el-radio label="后台自动发放" v-model="forms.dayss">
                    </el-radio>
                    <el-radio label="用户主动领取" v-model="forms.dayss">
                    </el-radio>
                  </el-form-item>
                  <el-form-item label="可领取用户">
                    <el-radio label="全体用户可用" v-model="forms.use">
                    </el-radio>
                    <el-radio label="全体会员可用" v-model="forms.use">
                    </el-radio>
                    <el-radio label="仅铂金会员可用" v-model="forms.use">
                    </el-radio>
                  </el-form-item>
                  <el-form-item label="优惠券描述">
                    <el-input class="inputboxsa" v-model="forms.descriptionss" placeholder="请描述优惠券" />
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmits">确定</el-button>
                    <el-button @click="toggleModals">取消</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script setup>
import Menu from '../../navbar/Menu.vue'
import LeftNav from '../../navbar/LeftNav.vue'
import TopNav from '../../navbar/TopNav.vue'
import axios from "axios";
import { onMounted, ref, toRaw, watch } from "vue";
import { login } from '@/api/userMG';
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../../network/storester.js'
// import { reactive } from "vue";
import { useRouter } from 'vue-router'


const lengths = ref(null)
const lenghta = ref([])
const type = ref('')
const types = ref('')
const coupons = ref([]);
const search = ref('')
const couponss = ref(null)
const showModal = ref(false);
const showModals = ref(false);
const router = useRouter();
// 在页面加载完成后发送查询请求
instance.get('/api/coupons/list')
  .then(response => {
    couponss.value = response.data.data
    coupons.value = toRaw(couponss.value)
    console.log(coupons.value);
  }).catch(error => {
    console.log(error);
    ElMessage({
      message: '请重新登录',
      type: 'error',
    })
    router.push('./')
  })
const store = ref([])
instance.get(`/api/stores`)
  .then(response => {
    console.log(response.data);
    store.value = response.data;
    console.log(store.value);
  })
  .catch(error => {
    console.log(error);
    ElMessage({
      showClose: true,
      message: '请登录',
      type: 'error',
    })
    router.push('./')
  })
const form = ref({
  id: '',
  category: '',
  descriptions: '',
  quantity: '',
  facevalue: '',
  doorsills: '',
  doorsilla: '',
  facevalues: '',
  // exchange: '',
  day: '',
  days: '',
  user: '全体用户可用',
  Fixedtime: '固定时间',
  mailbox: '',
  doorsill: '',
  name: '',
  value2: '',
  type: '',
  doorsillae: '',
  doorsillsz: '',
  discountLimitPrice: '',
  store: '',
})

const forms = ref({
  ids: '',
  categorys: '',
  descriptionss: '',
  quantitys: '',
  facevalues: '',
  doorsillss: '',
  facevaluess: '',
  // exchanges: '',
  dayss: '',
  use: '',
  Fixedtimes: '',
  doorsillsa: '',
  names: '',
  value2s: '',
  types: '',
  doorsillas: '',
  doorsillszs: '',
  stores: '',
})


var myData = localStorage.getItem('myData');
console.log(myData);
var sysStoreId = localStorage.getItem('sysStoreId')
console.log(sysStoreId);
var account = localStorage.getItem('account')
console.log(account);


const onSubmit = () => {
  //时间转换

  const a = form.value
  console.log(a.type);
  console.log(a.store);
  console.log(a.doorsills);
  const chineseTime = ref('');
  const date = new Date(a.value2[0]);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  chineseTime.value = `${year}.${month}.${day}`;
  console.log(chineseTime.value);
  const chineseTimea = ref('');
  const datea = new Date(a.value2[1]);
  const yeara = datea.getFullYear();
  const montha = String(datea.getMonth() + 1).padStart(2, '0');
  const daya = String(datea.getDate()).padStart(2, '0');
  chineseTimea.value = `${yeara}.${montha}.${daya}`;
  console.log(chineseTimea.value);
  const getTimes = ref('');
  const getTime = new Date().getTime(); //获取到当前时间戳
  var time = new Date(getTime); //创建一个日期对象
  const years = time.getFullYear();
  const months = String(time.getMonth() + 1).padStart(2, '0');
  const days = String(time.getDate()).padStart(2, '0');
  getTimes.value = `${years}-${months}-${days}`;
  console.log(getTimes.value);
  if (a.descriptions == '') {
    ElMessage({
      type: 'error',
      message: '请输入优惠券描述',
    })
    return;
  }
  if (a.name == '') {
    ElMessage({
      type: 'error',
      message: '请输入优惠券名称',
    })
    return;
  }
  if (a.quantity == '') {
    ElMessage({
      type: 'error',
      message: '请输入优惠券数量',
    })
    return;
  }
  if (a.store == '') {
    ElMessage({
      type: 'error',
      message: '请选择门店',
    })
    return;
  }
  if (form.value.value2 == '') {
    ElMessage({
      type: 'error',
      message: '请选择优惠券时间',
    })
    return;
  }
  if (a.facevalues == '') {
    ElMessage({
      type: 'error',
      message: '请输入优惠券面值',
    })
    return;
  }
  if (a.category == '') {
    ElMessage({
      type: 'error',
      message: '请输入优惠券类型',
    })
    return;
  }

  instance.get('/api/coupons/list')
    .then(response => {
      lengths.value = response.data.data
      lenghta.value = toRaw(lengths.value)
      console.log(lenghta.value.length);
      localStorage.setItem('lengthe', lenghta.value.length);
      var lengthe = localStorage.getItem('lengthe');
      var s = parseInt(lengthe) + 1;
      console.log(s);
      console.log(form.value.days);
      var c = ref('')
      var d = ref('')
      if (form.value.days == '用户自主领取') {
        form.value.days = 1
      }
      if (form.value.days == '后台自动发放') {
        form.value.days = 2
      }
      if (form.value.category !== '折扣券') {
        console.log(form.value.category);
        form.value.doorsills = c
        console.log(a.doorsills);
      }
      if (form.value.category == '折扣券') {
        c = form.value.doorsills
      }
      if (form.value.category === '满减券') {
        d.value = 2
        console.log(d.value);
      }
      if (form.value.category === '代金券') {
        d.value = 1
        console.log(d.value);
      }
      if (form.value.category === '折扣券') {
        d.value = 3
        console.log(d.value);
      }
      if (form.value.category === '兑换券') {
        d.value = 4
        console.log(d.value);
      }
      const news = {
        'createBy': myData,
        // 'createTime': getTimes.value,
        'discountLimitPrice': form.value.discountLimitPrice,
        'discountPercent': form.value.doorsillae,
        'usePrice': c.value,
        'userId': account,
        'takeType': form.value.days,
        'storeId': a.store,
        'templateId': s,
        'name': form.value.name,
        'number': form.value.quantity,
        'validStartTime': chineseTime.value,
        'validEndTime': chineseTimea.value,
        'updateBy': myData,
        'description': form.value.descriptions,
        'discountType': d.value,
        'discountPrice': form.value.facevalues,
        'create_by': myData,
        'remark': '',
        'status': 1
      }
      console.log(news);
      instance.post('/api/coupons', news)
        .then(response => {
          ElMessage({
            showClose: true,
            message: '新增成功',
            type: 'success',
          })
          //刷新页面
          instance.get('/api/coupons/list')
            .then(response => {
              couponss.value = response.data.data
              coupons.value = toRaw(couponss.value)
              console.log(coupons.value);
            }).catch(error => {
              console.log(error);
              ElMessage({
                message: '请重新登录',
                type: 'error',
              })
              router.push('./')
            })
        })
    }).catch(error => {
      ElMessage({
        showClose: true,
        message: '新增失败',
        type: 'error',
      })
      console.log(error);
    })
}


const toggleModala = () => {
  showModal.value = !showModal.value;
}
const toggleModal = () => {
  showModal.value = !showModal.value;
  form.value = {
    id: '',
    category: '',
    descriptions: '',
    quantity: '',
    facevalue: '',
    doorsills: '',
    doorsilla: '',
    facevalues: '',
    exchange: '',
    as: '',
    day: '',
    days: '',
    receive: '',
    Fixedtime: '',
    doorsill: '',
    name: '',
    value2: '',
    type: '',
  }
};
const toggleModals = () => {
  showModals.value = !showModals.value;
};


const currentTab = ref('')
//切换
const currentTaba = () => {
  currentTab.value = one.value
  console.log(currentTab);
  instance.get().then(response => {

  })
    .catch(error => {

    })
}
const currentTabb = () => {
  currentTab.value = two.value
  console.log(currentTab);
  axios.get().then(response => {

  })
    .catch(error => {

    })
}
const currentTabc = () => {
  currentTab.value = three.value
  console.log(currentTab);
  axios.get()
    .then(response => {

    })
    .catch(error => {

    })
}
//编辑
const dataa = ref(null)
const edit = ref('')
const edits = ref([])
const isDisabled = ref(null);
const isDisableds = ref(true)
const primary = (id) => {
  showModals.value = !showModals.value;
  console.log(id);
  dataa.value = id;
  instance.get(`/api/coupons/${dataa.value}`)
    .then(response => {
      edit.value = response.data.data
      edits.value = toRaw(edit.value)
      console.log(edits.value);
      const b = edits.value
      console.log(b.doorsillss);
      const Receive = ref('')
      console.log(b.discountType);
      const discount = ref('')
      if (b.discountType === 2) {
        discount.value = '满减券'
        console.log(discount.value);
      }
      if (b.discountType === 1) {
        discount.value = '代金券'
        console.log(discount.value);
      }
      if (b.discountType === 3) {
        discount.value = '折扣券'
        console.log(discount.value);
      }
      if (b.discountType === 4) {
        discount.value = '兑换券'
        console.log(discount.value);
      }
      if (b.takeType === 1) {
        Receive.value = '用户主动领取'
      }
      if (b.takeType === 2) {
        Receive.value = '后台自动发放'
      }
      if (b.discountLimitPrice === '' || b.discountLimitPrice === null || b.discountLimitPrice === undefined) {
        isDisabled.value = true
        forms.value.doorsillss == ''
        forms.value.doorsillsa === '无门槛'
        console.log(forms.value.doorsillsa);
      } else {
        isDisabled.value = false
        forms.value.doorsillsa === '0'
        console.log(forms.value.doorsillsa);
      }
      if (b.discountLimitPrice !== '') {
        isDisabled.value = false
      }
      const  store = ref('')
      if (b.storeId !== '') {
        store.value = '门店'
      }
      console.log(store.value);
      forms.value = ({
        ids: b.id,
        categorys: discount.value,
        quantitys: b.number,
        doorsillss: b.discountLimitPrice,
        facevaluess: b.discountPrice,
        // exchanges: '',
        descriptionss: b.description,
        days: '',
        dayss: Receive.value,
        use: '全体用户可用',
        Fixedtimes: '固定时间',
        doorsillsa: forms.value.doorsillsa,
        names: b.name,
        value2s: [b.validStartTime, b.validEndTime],
        types: store.value,
      })

    })
}

const as = () => {
  console.log(forms.value.doorsillsa);
  if (forms.value.doorsillsa === '0') {
    console.log(forms.value.doorsillsa);
    isDisabled.value = false
  } else {
    isDisabled.value = true
  }
}
const ass = () => {
  if (form.value.doorsill === '0') {
    console.log(form.value.doorsill);
    isDisableds.value = false
  } else {
    isDisableds.value = true
  }
}
// console.log(window.sessionStorage.setItem("myData", response.data.data.name)); 

const onSubmits = () => {
  console.log(forms.value.facevaluess);
  const as = forms.value
  console.log(as.doorsillss);
  const chineseTimes = ref('');
  const dates = new Date(as.value2s[0]);
  const years = dates.getFullYear();
  const months = String(dates.getMonth() + 1).padStart(2, '0');
  const days = String(dates.getDate()).padStart(2, '0');
  chineseTimes.value = `${years}-${months}-${days}`;
  console.log(chineseTimes.value);

  const chineseTimese = ref('');
  const datese = new Date(as.value2s[1]);
  const yearse = datese.getFullYear();
  const monthse = String(datese.getMonth() + 1).padStart(2, '0');
  const dayse = String(datese.getDate()).padStart(2, '0');
  chineseTimese.value = `${yearse}-${monthse}-${dayse}`;
  console.log(chineseTimese.value);

  console.log(forms.value.categorys);
  const typea = ref('')
  if (forms.value.categorys === '代金券') {
    console.log('niu');
    typea.value = '1'
  }
  if (forms.value.categorys === '满减券') {
    typea.value = '2'
  }
  if (forms.value.categorys === '折扣券') {
    console.log('niu');
    typea.value = '3'
  }
  if (forms.value.categorys === '兑换券') {
    typea.value = '4'
  }
  console.log(typeof forms.value.quantitys);
  const cdse = {
    'id': dataa.value,
    'name': forms.value.names,
    'number': forms.value.quantitys,
    'validStartTime': chineseTimes.value,
    'validEndTime': chineseTimese.value,
    'updateBy': myData,
    'description': forms.value.descriptionss,
    'discountType': typea.value,
    'discountPrice': forms.value.facevaluess
  }
  console.log(cdse);
  instance.put(`/api/coupons/UpdateCoupons`, cdse)
    .then(response => {
      ElMessage({
        showClose: true,
        message: '编辑成功',
        type: 'success',
      })
      //刷新页面
      window.location.reload()
    }
    ).catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '编辑失败',
        type: 'error',
      })
    }
    )
}
//搜素
const searchs = () => {
  console.log(search.value);
  // instance.get(`/api/coupons?search=${search.value}`)
  //   .then(response => {
  //     couponss.value = response.data.data
  //     coupons.value = toRaw(couponss.value)
  //   })
}
//删除优惠券
const deleteCoupon = (id) => {
  console.log(id);
  ElMessageBox.confirm(
    '您确定要删除该优惠券吗?',
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      instance.delete(`/api/coupons/${id}`)
      ElMessage({
        type: 'success',
        message: '删除成功',
      })
      window.location.reload()
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消删除',
      })
    })
}
</script>
<style scoped>
.main1 {
  display: flex;
  margin-top: 20vh;
  width: 85vw;
  height: 100vh;
}

.main_item1 {
  height: 100vh;
}

.upper {
  margin-top: 10vh;

}

.upper .line {
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  /* background-color: #ebeef5; */
  position: fixed;
  top: 25vh;
}

.handoff {
  margin-left: 20px;
  position: fixed;
}

.handoff .el-button {
  margin-right: 10px;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 8% 15% 15% 15%;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  left: 15vw;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.PopUps {
  position: relative;
  top: 20vh;
  left: 15vw;
  width: 85vw;
  height: 90vh;
}

.PopUps .appear {
  position: fixed;
  top: 10vh;
  right: 20vw;
}

.inputbox {
  width: 15vw;
}

.inputboxs {
  width: 5vw;
}

.apart {
  margin-left: 5vw;
}

.inputboxsa {
  width: 25vw;
}

.time {
  width: 40vw;
}

.Separated {
  margin-left: 1vw;
}

.inputboxab {
  width: 20vw;
}

.screening {
  position: fixed;
}

.screening .type {
  font-size: 14px;
  margin-right: 1vw;
  margin-left: 2vw;
}

.screening .FilterDox .el-select {
  width: 10vw;
}

.screening .SearchDox {
  margin-left: 2vw;
}

.main2 {
  margin-left: -4vw !important;
}
</style>