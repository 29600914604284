<template>
    <el-container class="Wrapper">
        <el-container class="containere">
            <el-main>
                <div class="addition">
                    <el-form :label-position="label - postion">
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品名称：</span>
                            <span class="input"><el-input v-model="input" placeholder="请输入商品名称" clearable /></span>
                        </p>
                        <p v-if="storeLV">
                            <span class="name"><i style="color: red;">*</i>商品门店：</span>
                            <span>
                                <!-- <el-select v-model="value" clearable placeholder="请选择商品的门店" @change="addsd">
                                    <el-option v-for="item in store" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select> -->
                                <el-select v-model="value1" multiple placeholder="请选择商品的门店" @change="addsd">
                                    <el-option v-for="item in store" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </span>
                        </p>
                        <p v-for="(item, index) in value1" :key="index" v-if="states === '0'">
                            <span class="name" v-if="value1 != ''"><i style="color: red;">*</i>商品分类：</span>
                            <span>
                                <el-select v-if="value1 != ''" v-model="value_1[index]" clearable placeholder="请选择商品的分类"
                                    @change="">
                                    <el-option v-for="item in managementes[index]" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </span>
                        </p>
                        <p v-else>
                            <span class="name"><i style="color: red;">*</i>商品分类：</span>
                            <span>
                                <el-select v-model="value_2" clearable placeholder="请选择商品的分类" @change="adds">
                                    <el-option v-for="item in managementes" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品状态：</span>
                            <span> <el-select v-model="state" clearable placeholder="请选择商品的状态" @change="addsde">
                                    <el-option v-for="item in managementesa" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select></span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>是否需要规格</span>
                            <span class="inputs">
                                <el-radio-group v-model="specification">
                                    <el-radio value="1" label="是"></el-radio>
                                    <el-radio value="0" label="否"></el-radio>
                                </el-radio-group>
                            </span>
                        </p>
                        <p v-if="specification == '是'">
                            <span class="name"><i style="color: red;">*</i>增减规格</span>
                            <span class="inputs">
                                <el-button @click="addea(index)">+</el-button>
                                <el-button @click="reduce(index)" :disabled="flag">-</el-button>
                            </span>
                        </p>
                        <p v-if="specification == '否'">
                            <span class="name"><i style="color: red;">*</i>商品库存</span>
                            <span class="input"><el-input v-model="specStock" placeholder="请输入商品库存" clearable
                                    v-on:keyup="checkNun" /></span>
                        </p>
                        <p v-if="specification == '否'">
                            <span class="name"><i style="color: red;">*</i>商品价格:</span>
                            <span class="input"><el-input v-model="specPrice" placeholder="请输入价格" clearable
                                    v-on:keyup="checkNus" /></span>
                            <span style="color: red; font-size: 12px;" v-if="!isNus">请输入数字</span>
                        </p>
                        <div v-for="item in checkList" v-if="specification == '是'">
                            <p>
                                <span class="name"><i style="color: red;">*</i>商品规格:</span>
                                <span class="inputs">
                                    <el-input v-model="item.specName" placeholder="请输入商品规格" clearable
                                        v-on:keyup="checkNun" />
                                </span>
                            </p>
                            <p>
                                <span class="name"><i style="color: red;">*</i>商品库存</span>
                                <span class="input"><el-input v-model="item.specStock" placeholder="请输入商品库存" clearable
                                        v-on:keyup="checkNun" /></span>
                            </p>
                            <p>
                                <span class="name"><i style="color: red;">*</i>商品价格:</span>
                                <span class="input"><el-input v-model="item.specPrice" placeholder="请输入价格" clearable
                                        v-on:keyup="checkNus" /></span>
                                <span style="color: red; font-size: 12px;" v-if="!isNus">请输入数字</span>
                            </p>
                        </div>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品封面图</span>
                            <span class="avatar1">
                                <el-upload v-model:file-list="fileList"
                                    action="https://199shq.com/api/announcement/upload" name="file"
                                    limit="5" list-type="picture-card" :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove">
                                    <el-icon>
                                        +
                                    </el-icon>
                                </el-upload>
                                <el-dialog v-model="dialogVisible">
                                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                                </el-dialog>
                            </span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品详情图</span>
                        <div class="xiang">
                            <span class="yi avatar1">
                                <el-upload v-model:file-list="fileListt"
                                    action="https://199shq.com/api/announcement/upload" name="file"
                                    limit="10" list-type="picture-card" :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove">
                                    <el-icon>
                                        +
                                    </el-icon>
                                </el-upload>
                                <el-dialog v-model="dialogVisiblet">
                                    <img w-full :src="dialogImageUrlt" alt="Preview Image" />
                                </el-dialog>
                            </span>
                        </div>
                        </p>
                        <p>
                            <span class="name">商品详情视频</span>
                            <span class="avatar1">
                                <el-upload class="upload-demo" action="" :http-request="upload_41"
                                    :before-upload="beforeAvatarUploads" multiple :limit="3">
                                    <video v-if="videoUrl !== '' && videoUrl != undefined" :src="videoUrl" controls
                                        autoplay></video>
                                    <el-button v-else type="primary">点击上传</el-button>
                                </el-upload>
                                <img v-if="videoUrl != '' && videoUrl != undefined" @click="videoUrl1" class="delete"
                                    src="../assets/delete.png">
                            </span>
                        </p>
                        <!-- <p>
                            <span class="name"><i style="color: red;"></i>运费模板:</span>
                            <span> <el-select v-model="value_2" clearable placeholder="请选择" @change="">
                                    <el-option v-for="item in express" :key="item.value" :label="item.label"
                                        :value="item.name" />
                                </el-select></span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;"></i>关联用户标签:</span>
                            <span> <el-select v-model="value_3" clearable placeholder="请选择" @change="">
                                    <el-option v-for="item in Alabel" :key="item.value" :label="item.label"
                                        :value="item.name" />
                                </el-select></span>
                        </p> -->
                        <p class="button">
                            <el-button type="primary" @click="add" @change="addads">保存</el-button>
                            <el-button @click="cancel">取消</el-button>
                        </p>
                    </el-form>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw } from 'vue';
import { Plus } from 'element-plus';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Edit } from 'vxe-table';
import { useRouter, useRoute } from 'vue-router';

const value1 = ref([])
const router = useRouter();
const fileList = ref([])
const fileListt = ref([])
const state = ref('')
const input = ref('')
const stock = ref('')
const sourcea = ref('')
const input_2 = ref('')
const value = ref('')
const value_1 = ref([])
const value_2 = ref('')
const value_3 = ref('')
const input_3 = ref('')
const checkList = ref([])
const store = ref([])
const express = ref([])
const Alabel = ref([])
const storeLV = ref(false);
const textarea = ref('')
const managementes = ref([]);
const searches = ref('')
const imageUrl21 = ref('')
const imageUrl22 = ref('')
const imageUrl23 = ref('')
const imageUrl24 = ref('')
const imageUrl25 = ref('')
const imageUrl31 = ref('')
const imageUrl32 = ref('')
const imageUrl33 = ref('')
const imageUrl34 = ref('')
const imageUrl35 = ref('')
const imageUrl36 = ref('')
const imageUrl37 = ref('')
const imageUrl38 = ref('')
const imageUrl39 = ref('')
const imageUrl40 = ref('')
const imageUrl41 = ref('')
const imgurl21 = ref('')
const imgurl22 = ref('')
const imgurl23 = ref('')
const imgurl24 = ref('')
const imgurl25 = ref('')
const imgurl31 = ref('')
const imgurl32 = ref('')
const imgurl33 = ref('')
const imgurl34 = ref('')
const imgurl35 = ref('')
const imgurl36 = ref('')
const imgurl37 = ref('')
const imgurl38 = ref('')
const imgurl39 = ref('')
const imgurl40 = ref('')
const imgurl41 = ref('')
const namea = ref('')
const videoUrl = ref('')
const nulls = ref(undefined)
const isNum = ref(true)
const specStock = ref('')
const specPrice = ref('')
const isNun = ref(true)
const isNus = ref(true)
checkList.value = [{
    specStock: '',
    specPrice: '',
    specName: ''
}]
const handleRemove = (uploadFile, uploadFiles) => {
  // 点击删除的时候将对象的值设置为null
//   c.value.mediaUrl = null;
  // 发出删除文件的请求
  let url = uploadFile.response.data;//得到当前的文件路径
  instance.delete("/api/upload/remove?url="+url)
      .then((response) =>{
        if (response.data.state == 20000){
          ElMessage.success("删除文件完成！")
        }
      })


  // console.log(uploadFile, uploadFiles)
}
const addea = () => {
    if (checkList.value.length <= 3) {
        const news = {
            specStock: '',
            specPrice: '',
            specName: ''
        }
        checkList.value.push(news)
        flags()
    } else {
        ElMessage({
            showClose: true,
            message: '最多添加四个规格',
            type: 'error',
        })
    }
}
const specification = ref('')
const flag = ref(true)
const reduce = () => {
    console.log('进入')
    checkList.value.pop();
    flags()
}


const flags = () => {
    flag.value = checkList.value.length < 2 ? true : false;
};
console.log(checkList.value);
const reg = /^\d{0,9}(\.\d{0,9})?$/
const checkNum = () => {
    // 匹配数字的正则表达式
    if (!reg.test(input_2.value)) {
        isNum.value = false
        console.log('niu');
    } else {
        isNum.value = true
        console.log('ni');
    }
}
const states = localStorage.getItem('permissionsStatus')
const managementesa = [
    {
        value: '1',
        label: '上架',
    },
    {
        value: '0',
        label: '下架',
    }
]

const addsde = (value) => {
    state.value = value
    console.log(state.value);
}
const adds = () => {

}
const checkNus = () => {
    // 匹配数字的正则表达式
    if (!reg.test(stock.value)) {
        isNus.value = false
        console.log('niu');
    } else {
        isNus.value = true
        console.log('ni');
    }
}
const checkNun = () => {
    // 匹配数字的正则表达式
    if (!reg.test(input_3.value)) {
        isNun.value = false
    } else {
        isNun.value = true
    }
}


const videoUrl1 = () => {
    namea.value = ''
    videoUrl.value = ''
}
const cancel = () => {
    router.push('./commodity')
}
//判断账号是否是管理员权限
if (localStorage.getItem('permissionsStatus') === '0') { //账号属于管理员
    storeLV.value = true;
    instance.get(`/api/stores`)
        .then(response => {
            console.log(response.data);
            store.value = response.data;
            console.log(store.value);
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('./')
        })
} else {
    storeLV.value = false;
    value.value = "非管理员";
    const id = localStorage.getItem('sysStoreId');
    console.log(id);
    ///api/stores/cate/${b}
    instance.get(`/api/stores/cate/${id}`)
        .then(response => {
            console.log(id);
            searches.value = response.data.data;
            managementes.value = toRaw(searches.value);
            console.log(managementes.value);
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('./')
        })
}

const beforeAvatarUpload = (file) => {
    const isJPG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 1000);
    clearTimeout(timer.value);

    if (!isJPG) {
        alert('上传头像图片只能是 JPG 格式!');
    }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
};

const upload_40 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl40.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl40.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_41 = (param) => {
    console.log(param);
    const formData = new FormData();
    formData.append('file', param.file);
    console.log("2222");
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl41.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl41.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
}
const beforeAvatarUploads = (file) => {
    console.log(file);
    if (file.type !== 'video/mp4') {
        ElMessage({
            type: 'error',
            message: '请上传mp4格式视频',
        })
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    instance.post('/api/common/uploadVideo', formData)
        .then(response => {
            console.log('上传成功');
            console.log(response.data.data);
            namea.value = response.data.data;
            videoUrl.value = URL.createObjectURL(file)
            console.log(namea.value);
        }).catch(response => {
            console.log(response);
            console.log('上传失败')
        })

    console.log(formData);
    // const isJPG = file.type === '.mp4';
    // const isLt2M = file.size / 1024 / 1024 < 200;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 10000);
    clearTimeout(timer.value);

    // if (!isJPG) {
    //     alert('上传头像图片只能是 JPG 格式!');
    // }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    console.log("1111");
    return isJPG && isLt2M;
};
const addsd = (id) => {
    console.log(id);
    console.log(value1.value.length);
    if (value1.value !== '') {
        for (let i = 0; i < value1.value.length; i++) {
            console.log(id[i]);
            instance.get(`/api/stores/cate/${id[i]}`)
                .then(response => {
                    console.log(id);
                    managementes.value[i] = response.data.data;
                    // managementes.value = toRaw(searches.value);
                    console.log(managementes.value[i]);
                })
        }
    }
}

const addid = ref();

const addads = (id) => {
    console.log(id);
    addid.value = id;
}

const add = () => {
    console.log(imgurl21.value);
    console.log(addid.value);
    console.log(value1.value.length);
    console.log(value_1.value);
    ElMessageBox.confirm(
        '确定添加该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            if (input.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品名称未填写',
                })
                return;
            }
            // if (input_3.value === '') {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品佣金未填写',
            //     })
            //     return;
            // }
            // if (input_2.value / 10 <= input_3.value) {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品佣金大于价格10%',
            //     })
            //     return;
            // }
            for (let i = 0; i < value_1.value.length; i++) {
                if (value_1.value[i] === '') {
                    ElMessage({
                        type: 'error',
                        message: '添加失败，商品分类未填写',
                    })
                    return;
                }
            }

            if (value1.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品门店未填写',
                })
                return;
            }
            // if (input_2.value === '') {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品价格未填写',
            //     })
            //     return;
            // }
            // if (stock.value === '') {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品库存未填写',
            //     })
            //     return;
            // }
            if (isNum.value === false) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品价格只能填写数字',
                })
                return;
            }
            // if (isNun.value === false) {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品佣金只能填写数字',
            //     })
            //     return;
            // }
            if (state.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，请添加商品状态',
                })
                return;
            }
            if (isNus.value === false) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品库存只能填写数字',
                })
                return;
            }
            if (fileList.value[0]=== undefined) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品展示图未添加',
                })
                return;
            }
            if (fileListt.value[0]=== undefined) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品详情图未添加',
                })
                return;
            }

            if (fileList.value[4] !== undefined) {
                imgurl21.value = fileList.value[0].response.data
                imgurl22.value = fileList.value[1].response.data
                imgurl23.value = fileList.value[2].response.data
                imgurl24.value = fileList.value[3].response.data
                imgurl25.value = fileList.value[4].response.data
            } else if (fileList.value[3] !== undefined) {
                imgurl21.value = fileList.value[0].response.data
                imgurl22.value = fileList.value[1].response.data
                imgurl23.value = fileList.value[2].response.data
                imgurl24.value = fileList.value[3].response.data
                imgurl25.value = ''
            } else if (fileList.value[2] !== undefined) {
                imgurl21.value = fileList.value[0].response.data
                imgurl22.value = fileList.value[1].response.data
                imgurl23.value = fileList.value[2].response.data
                imgurl24.value = ''
                imgurl25.value = ''
            } else if (fileList.value[1] !== undefined) {
                imgurl21.value = fileList.value[0].response.data
                imgurl22.value = fileList.value[1].response.data
                imgurl23.value = ''
                imgurl24.value = ''
                imgurl25.value = ''
            } else if (fileList.value[0] !== undefined) {
                imgurl21.value = fileList.value[0].response.data
                imgurl22.value = ''
                imgurl23.value = ''
                imgurl24.value = ''
                imgurl25.value = ''
            }

            if (fileListt.value[9] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data
                imgurl34.value = fileListt.value[3].response.data
                imgurl35.value = fileListt.value[4].response.data
                imgurl36.value = fileListt.value[5].response.data
                imgurl37.value = fileListt.value[6].response.data
                imgurl38.value = fileListt.value[7].response.data
                imgurl39.value = fileListt.value[8].response.data
                imgurl40.value = fileListt.value[9].response.data
            } else if (fileListt.value[8] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data
                imgurl34.value = fileListt.value[3].response.data
                imgurl35.value = fileListt.value[4].response.data
                imgurl36.value = fileListt.value[5].response.data
                imgurl37.value = fileListt.value[6].response.data
                imgurl38.value = fileListt.value[7].response.data
                imgurl39.value = fileListt.value[8].response.data
                imgurl40.value = ''
            } else if (fileListt.value[7] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data
                imgurl34.value = fileListt.value[3].response.data
                imgurl35.value = fileListt.value[4].response.data
                imgurl36.value = fileListt.value[5].response.data
                imgurl37.value = fileListt.value[6].response.data
                imgurl38.value = fileListt.value[7].response.data
                imgurl39.value = ''
                imgurl40.value = ''
            } else if (fileListt.value[6] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data
                imgurl34.value = fileListt.value[3].response.data
                imgurl35.value = fileListt.value[4].response.data
                imgurl36.value = fileListt.value[5].response.data
                imgurl37.value = fileListt.value[6].response.data
                imgurl37.value = ''
                imgurl38.value = ''
                imgurl39.value = ''
                imgurl40.value = ''
            } else if (fileListt.value[5] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data
                imgurl34.value = fileListt.value[3].response.data
                imgurl35.value = fileListt.value[4].response.data
                imgurl36.value = fileListt.value[5].response.data
                imgurl37.value = ''
                imgurl38.value = ''
                imgurl39.value = ''
                imgurl40.value = ''
            } else if (fileListt.value[4] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data
                imgurl34.value = fileListt.value[3].response.data
                imgurl35.value = fileListt.value[4].response.data
                imgurl36.value = ''
                imgurl37.value = ''
                imgurl38.value = ''
                imgurl39.value = ''
                imgurl40.value = ''
                imgurl40.value = fileListt.value[9].response.data
            } else if (fileListt.value[3] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data
                imgurl34.value = fileListt.value[3].response.data
                imgurl35.value = ''
                imgurl36.value = ''
                imgurl37.value = ''
                imgurl38.value = ''
                imgurl39.value = ''
                imgurl40.value = ''
            } else if (fileListt.value[2] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = fileListt.value[2].response.data

                imgurl34.value = ''
                imgurl35.value = ''
                imgurl36.value = ''
                imgurl37.value = ''
                imgurl38.value = ''
                imgurl39.value = ''
                imgurl40.value = ''
            } else if (fileListt.value[1] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = fileListt.value[1].response.data
                imgurl33.value = ''
                imgurl34.value = ''
                imgurl35.value = ''
                imgurl36.value = ''
                imgurl37.value = ''
                imgurl38.value = ''
                imgurl39.value = ''
                imgurl40.value = ''
            } else if (fileListt.value[0] !== undefined) {
                imgurl31.value = fileListt.value[0].response.data
                imgurl32.value = ''
                imgurl33.value = ''
                imgurl34.value = ''
                imgurl35.value = ''
                imgurl36.value = ''
                imgurl37.value = ''
                imgurl38.value = ''
                imgurl39.value = ''
                imgurl40.value = ''
            }

            if (localStorage.getItem('permissionsStatus') === '0') {
                for (let i = 0; i < value_1.value.length; i++) {
                    if (specification.value == '否') {
                        if (specPrice.value == '') {
                            ElMessage({
                                message: '商品修改失败，商品库存未添加',
                                type: 'error',
                            })
                            return;
                        }
                        if (specStock.value == '') {
                            ElMessage({
                                message: '商品修改失败，商品库存未添加',
                                type: 'error',
                            })
                            return;
                        }
                        const ediera = {
                            // id: route.query.msg,
                            name: input.value,
                            price: specPrice.value,
                            systemType: 0,
                            specification: 0,
                            commission: 0,
                            categoriesId: value_1.value[i],
                            stock: specStock.value,
                            // categoriesName : value_1.value ,
                            categoriesId: value_1.value[i],
                            productStatus: state.value,
                            coverUrl: imgurl21.value,
                            coverUrl2: imgurl22.value,
                            coverUrl3: imgurl23.value,
                            coverUrl4: imgurl24.value,
                            coverUrl5: imgurl25.value,
                        };
                        //     const edit = {
                        //     "name": input.value,
                        //     "price": input_2.value,
                        //     "commission": 0,
                        //     "systemType" :0,
                        //     // "categoriesName" : value_1.value ,
                        //     "categoriesId": value_1.value[i],
                        //     "coverUrl": imgurl21.value,
                        //     "coverUrl2": imgurl22.value,
                        //     "coverUrl3": imgurl23.value,
                        //     "coverUrl4": imgurl24.value,
                        //     "coverUrl5": imgurl25.value,
                        //     "stock": stock.value,
                        //     "productStatus": state.value,
                        // }
                        console.log(ediera);
                        instance.post('/api/tproduct', ediera)
                            .then(response => {
                                console.log(response.data.data);
                                console.log('上传成功');
                                const edit2 = {
                                    "productId": response.data.data,
                                    "img1": imgurl31.value,
                                    "img2": imgurl32.value,
                                    "img3": imgurl33.value,
                                    "img4": imgurl34.value,
                                    "img5": imgurl35.value,
                                    "img6": imgurl36.value,
                                    "img7": imgurl37.value,
                                    "img8": imgurl38.value,
                                    "img9": imgurl39.value,
                                    "img10": imgurl40.value
                                }
                                instance.post('/api/details', edit2)
                                    .then(response => {
                                        console.log(response.data.data);
                                        console.log('上传成功');
                                        ElMessage({
                                            type: 'success',
                                            message: '添加成功',
                                        })
                                    }).catch(error => {
                                        console.log(error);
                                        console.log('上传失败');
                                    })
                                if (namea.value !== '') {
                                    const edit3 = {
                                        "productId": response.data.data,
                                        "videoName1": namea.value
                                    }
                                    instance.post('/api/productVideo', edit3)
                                        .then(response => {
                                            console.log(response);
                                            console.log('上传成功');
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                }
                            }).catch(error => {
                                console.log(error);
                                console.log('上传失败');
                            })
                    } else {
                        for (let i = 0; i < checkList.value.length; i++) {
                            // const element = array[i];
                            if (checkList.value[i].specPrice === '' || checkList.value[i].specStock === '' || checkList.value[i].specName === '') {
                                ElMessage({
                                    message: '商品修改失败，商品规格，价格，库存存在未输入',
                                    type: 'error',
                                })
                                return;
                            }

                        }
                        if (checkList.value[3] !== undefined) {


                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                systemType: 0,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: checkList.value[1].specName,
                                specPrice2: checkList.value[1].specPrice,
                                specStock2: checkList.value[1].specStock,
                                specName3: checkList.value[2].specName,
                                specPrice3: checkList.value[2].specPrice,
                                specStock3: checkList.value[2].specStock,
                                specName4: checkList.value[3].specName,
                                specPrice4: checkList.value[3].specPrice,
                                specStock4: checkList.value[3].specStock,
                                commission: 0,
                                stock: Number(checkList.value[0].specStock) + Number(checkList.value[1].specStock) + Number(checkList.value[2].specStock) + Number(checkList.value[3].specStock),
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    console.log('上传成功');
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                            ElMessage({
                                                type: 'success',
                                                message: '添加成功',
                                            })
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                        if (checkList.value[2] !== undefined) {
                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                systemType: 0,
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: checkList.value[1].specName,
                                specPrice2: checkList.value[1].specPrice,
                                specStock2: checkList.value[1].specStock,
                                specName3: checkList.value[2].specName,
                                specPrice3: checkList.value[2].specPrice,
                                specStock3: checkList.value[2].specStock,
                                specName4: '',
                                specPrice4: '',
                                specStock4: '',
                                commission: 0,
                                stock: Number(checkList.value[0].specStock) + Number(checkList.value[1].specStock) + Number(checkList.value[2].specStock),
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    console.log('上传成功');
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                            ElMessage({
                                                type: 'success',
                                                message: '添加成功',
                                            })
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                        if (checkList.value[1] !== undefined) {
                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: checkList.value[1].specName,
                                specPrice2: checkList.value[1].specPrice,
                                specStock2: checkList.value[1].specStock,
                                specName3: '',
                                specPrice3: '',
                                specStock3: '',
                                specName4: '',
                                specPrice4: '',
                                specStock4: '',
                                commission: 0,
                                stock: Number(checkList.value[0].specStock) + Number(checkList.value[1].specStock),
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    console.log('上传成功');
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                            ElMessage({
                                                type: 'success',
                                                message: '添加成功',
                                            })
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                        if (checkList.value[0] !== undefined) {
                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: '',
                                specPrice2: '',
                                specStock2: '',
                                specName3: '',
                                specPrice3: '',
                                specStock3: '',
                                specName4: '',
                                specPrice4: '',
                                specStock4: '',
                                commission: 0,
                                stock: checkList.value[0].specStock,
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    console.log('上传成功');
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                            ElMessage({
                                                type: 'success',
                                                message: '添加成功',
                                            })
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                    }

                }
            } else {
                for (let i = 0; i < value_1.value.length; i++) {
                    if (specification.value == '否') {
                        if (specPrice.value == '') {
                            ElMessage({
                                message: '商品修改失败，商品库存未添加',
                                type: 'error',
                            })
                            return;
                        }
                        if (specStock.value == '') {
                            ElMessage({
                                message: '商品修改失败，商品库存未添加',
                                type: 'error',
                            })
                            return;
                        }
                        const ediera = {
                            // id: route.query.msg,
                            name: input.value,
                            price: specPrice.value,
                            systemType: 0,
                            specification: 0,
                            commission: 0,
                            // categoriesName : value_1.value ,
                            categoriesId: value_1.value[i],
                            stock: specStock.value,
                            productStatus: state.value,
                            coverUrl: imgurl21.value,
                            coverUrl2: imgurl22.value,
                            coverUrl3: imgurl23.value,
                            coverUrl4: imgurl24.value,
                            coverUrl5: imgurl25.value,
                        };
                        //     const edit = {
                        //     "name": input.value,
                        //     "price": input_2.value,
                        //     "commission": 0,
                        //     "systemType" :0,
                        //     // "categoriesName" : value_1.value ,
                        //     "categoriesId": value_1.value[i],
                        //     "coverUrl": imgurl21.value,
                        //     "coverUrl2": imgurl22.value,
                        //     "coverUrl3": imgurl23.value,
                        //     "coverUrl4": imgurl24.value,
                        //     "coverUrl5": imgurl25.value,
                        //     "stock": stock.value,
                        //     "productStatus": state.value,
                        // }
                        console.log(ediera);
                        instance.post('/api/tproduct', ediera)
                            .then(response => {
                                console.log(response.data.data);
                                console.log('上传成功');
                                const edit2 = {
                                    "productId": response.data.data,
                                    "img1": imgurl31.value,
                                    "img2": imgurl32.value,
                                    "img3": imgurl33.value,
                                    "img4": imgurl34.value,
                                    "img5": imgurl35.value,
                                    "img6": imgurl36.value,
                                    "img7": imgurl37.value,
                                    "img8": imgurl38.value,
                                    "img9": imgurl39.value,
                                    "img10": imgurl40.value
                                }
                                instance.post('/api/details', edit2)
                                    .then(response => {
                                        console.log(response.data.data);
                                        console.log('上传成功');
                                        ElMessage({
                                            type: 'success',
                                            message: '添加成功',
                                        })
                                    }).catch(error => {
                                        console.log(error);
                                        console.log('上传失败');
                                    })
                                if (namea.value !== '') {
                                    const edit3 = {
                                        "productId": response.data.data,
                                        "videoName1": namea.value
                                    }
                                    instance.post('/api/productVideo', edit3)
                                        .then(response => {
                                            console.log(response);
                                            console.log('上传成功');
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                }
                            }).catch(error => {
                                console.log(error);
                                console.log('上传失败');
                            })
                    } else {
                        for (let i = 0; i < checkList.value.length; i++) {
                            // const element = array[i];
                            if (checkList.value[i].specPrice === '' || checkList.value[i].specStock === '' || checkList.value[i].specName === '') {
                                ElMessage({
                                    message: '商品修改失败，商品规格，价格，库存存在未输入',
                                    type: 'error',
                                })
                                return;
                            }

                        }
                        if (checkList.value[3] !== undefined) {


                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                systemType: 0,
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: checkList.value[1].specName,
                                specPrice2: checkList.value[1].specPrice,
                                specStock2: checkList.value[1].specStock,
                                specName3: checkList.value[2].specName,
                                specPrice3: checkList.value[2].specPrice,
                                specStock3: checkList.value[2].specStock,
                                specName4: checkList.value[3].specName,
                                specPrice4: checkList.value[3].specPrice,
                                specStock4: checkList.value[3].specStock,
                                commission: 0,
                                stock: Number(checkList.value[0].specStock) + Number(checkList.value[1].specStock) + Number(checkList.value[2].specStock) + Number(checkList.value[3].specStock),
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    console.log('上传成功');
                                    ElMessage({
                                        type: 'success',
                                        message: '添加成功',
                                    })
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                        if (checkList.value[2] !== undefined) {
                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                systemType: 0,
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: checkList.value[1].specName,
                                specPrice2: checkList.value[1].specPrice,
                                specStock2: checkList.value[1].specStock,
                                specName3: checkList.value[2].specName,
                                specPrice3: checkList.value[2].specPrice,
                                specStock3: checkList.value[2].specStock,
                                specName4: '',
                                specPrice4: '',
                                specStock4: '',
                                commission: 0,
                                stock: Number(checkList.value[0].specStock) + Number(checkList.value[1].specStock) + Number(checkList.value[2].specStock),
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    console.log('上传成功');
                                    ElMessage({
                                        type: 'success',
                                        message: '添加成功',
                                    })
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                        if (checkList.value[1] !== undefined) {
                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: checkList.value[1].specName,
                                specPrice2: checkList.value[1].specPrice,
                                specStock2: checkList.value[1].specStock,
                                specName3: '',
                                specPrice3: '',
                                specStock3: '',
                                specName4: '',
                                specPrice4: '',
                                specStock4: '',
                                commission: 0,
                                stock: Number(checkList.value[0].specStock) + Number(checkList.value[1].specStock),
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    ElMessage({
                                        type: 'success',
                                        message: '添加成功',
                                    })
                                    console.log('上传成功');
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                        if (checkList.value[0] !== undefined) {
                            const ediera = {
                                // id: route.query.msg,
                                name: input.value,
                                price: checkList.value[0].specPrice,
                                specification: 1,
                                // categoriesName : value_1.value ,
                                categoriesId: value_1.value[i],
                                specName: checkList.value[0].specName,
                                specPrice: checkList.value[0].specPrice,
                                specStock: checkList.value[0].specStock,
                                specName2: '',
                                specPrice2: '',
                                specStock2: '',
                                specName3: '',
                                specPrice3: '',
                                specStock3: '',
                                specName4: '',
                                specPrice4: '',
                                specStock4: '',
                                commission: 0,
                                stock: checkList.value[0].specStock,
                                productStatus: state.value,
                                coverUrl: imgurl21.value,
                                coverUrl2: imgurl22.value,
                                coverUrl3: imgurl23.value,
                                coverUrl4: imgurl24.value,
                                coverUrl5: imgurl25.value,
                            };
                            instance.post('/api/tproduct', ediera)
                                .then(response => {
                                    console.log(response.data.data);
                                    ElMessage({
                                        type: 'success',
                                        message: '添加成功',
                                    })
                                    const edit2 = {
                                        "productId": response.data.data,
                                        "img1": imgurl31.value,
                                        "img2": imgurl32.value,
                                        "img3": imgurl33.value,
                                        "img4": imgurl34.value,
                                        "img5": imgurl35.value,
                                        "img6": imgurl36.value,
                                        "img7": imgurl37.value,
                                        "img8": imgurl38.value,
                                        "img9": imgurl39.value,
                                        "img10": imgurl40.value
                                    }
                                    instance.post('/api/details', edit2)
                                        .then(response => {
                                            console.log(response.data.data);
                                            console.log('上传成功');
                                        }).catch(error => {
                                            console.log(error);
                                            console.log('上传失败');
                                        })
                                    if (namea.value !== '') {
                                        const edit3 = {
                                            "productId": response.data.data,
                                            "videoName1": namea.value
                                        }
                                        instance.post('/api/productVideo', edit3)
                                            .then(response => {
                                                console.log(response);
                                                console.log('上传成功');
                                            }).catch(error => {
                                                console.log(error);
                                                console.log('上传失败');
                                            })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            return;
                        }
                    }

                }
            }
            // router.push('./commodity')
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消添加',
            })
        })
};

</script>

<style>
.containere {
    position: fixed;
    top: 10vh;
    left: 15vw;
}


.addition {
    width: 100vw;
    height: 80vh;
}

.addition .el-input {
    width: 25vw;
}

.addition p {
    padding-top: 5vh;
    display: flex;
}

.addition .el-textarea {
    width: 25vw;
}

.name {
    width: 9%;
}

.addition .input {
    display: flex;
}

.addition .button {
    display: flex;
    margin-left: 12vw;
}


.addition .xian .avatar-uploader {
    display: inline-block;
}

.addition .xian {
    width: 59vw;
}

.addition video {
    width: 20vw;
    height: 10vw;
}

.el-select-dropdown {
    width: 25vw !important;
}
</style>