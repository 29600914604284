<!-- 管理用户 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="feedbackessw">
                        <div class="feedbackesswhead">
                            <el-input v-model="feedinput" placeholder="请输入用户ID" @keyup.enter="feedbackesswQuery"
                                class="feedbackesswSearch"></el-input>
                            <el-button type="primary" @click="feedbackesswQuery"
                                class="feedbackesswSearchBtn">查询</el-button>
                        </div>
                        <div class="feedbackesswbody">
                            <el-table :data="ManageUsers" height="70vh" style="width: 100%" v-loading="loading"
                                element-loading-text="加载中，请稍后...">
                                <el-table-column prop="wxUserId" label="用户ID" width="40" />
                                <el-table-column prop="nickName" label="微信用户名" width="40" />
                                <el-table-column prop="avatarUrl" label="头像" width="80">
                                    <template #default="scope">
                                        <div style="width: 50px;height: 50px;display: flex;align-items: center;"
                                            v-if="scope.row.avatarUrl !== null && scope.row.avatarUrl !== '' && scope.row.avatarUrl !== undefined">
                                            <el-image fit="fill" lazy :src="`${scope.row.avatarUrl}`">
                                            </el-image>
                                        </div>
                                        <div style="width: 60px;height: 60px;display: flex;align-items: center;" v-else>
                                            <span>暂无头像</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="gender" label="性别" width="100">
                                    <template #default="scope">
                                        <span v-if="scope.row.gender == 0 || scope.row.gender == null">未知</span>
                                        <span v-else-if="scope.row.gender == 1">男</span>
                                        <span v-else-if="scope.row.gender == 2">女</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="120">
                                    <template #default="scope">
                                        <el-button type="primary" @click="edit(scope.row.wxUserId)">编辑</el-button>
                                        <el-button type="danger" @click="deelete(scope.row.wxUserId)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="limit">
                                <div v-if="showModal" class="modal">
                                    <div class="modal-contents">
                                        <span class="close" @click="toggleModals">&times;</span>
                                        <span class="clos">修改用户信息</span>
                                        <el-form :model="form" label-width="120px">
                                            <el-form-item label="用户名">
                                                <el-input v-model="form.name" class="inpus" />
                                            </el-form-item>
                                            <el-form-item label="选择用户性别" class="inpus">
                                                <el-select v-model="form.gender" placeholder="请选择">
                                                    <el-option label="未知" value="0" />
                                                    <el-option label="男" value="1" />
                                                    <el-option label="女" value="2" />
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item>
                                                <template #default="row">
                                                    <el-button type="primary"
                                                        @click="onSubmits(row.wxUserId)">确定</el-button>
                                                    <el-button @click="toggleModals">取消</el-button>
                                                </template>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="feedbackesswfoot">
                            <div class="demo-pagination-blocks">
                                <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                    @size-change="handleSizeChanges" @current-change="handleCurrentChanges"
                                    :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const form = ref({
    name: '',
    gender: '',
})
const tatol = ref(0);
const ManageUsers = ref([])
const ManageUsersw = ref(null)
const showModal = ref(false);
const loading = ref(true)
const fetch = ref('')
const feedinput = ref('')
const router = useRouter();
const toggleModals = () => {
    showModal.value = !showModal.value;
}
instance.get('/api/wxuser/getAllUser')
    .then(response => {
        tatol.value = response.data.data.length
        console.log(tatol.value);
        loading.value = false
    })
    .catch(error => {
        console.log(error);
        console.error(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
instance.get(`/api/wxuser/paginationWxUser?&pageNum=${1}&pageSize=${10}`)
    .then(response => {
        ManageUsersw.value = response.data
        ManageUsers.value = toRaw(ManageUsersw.value)
        console.log(ManageUsers.value);
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
const deelete = (wxUserId) => {
    ElMessageBox.confirm(
        '您确认要删除该用户嘛?',
        'Warning',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            loading.value = true
            instance.delete(`/api/UserControl/${wxUserId}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    instance.get('/api/wxuser/getAllUser')
                        .then(response => {
                            tatol.value = response.data.data.length
                            console.log(tatol.value);
                        })
                        .catch(error => {
                            console.log(error);
                            console.error(error);
                            ElMessage({
                                message: '请重新登录',
                                type: 'error',
                            })
                            router.push('./')
                        })
                    instance.get(`/api/wxuser/paginationWxUser?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                        .then(response => {
                            ManageUsersw.value = response.data
                            ManageUsers.value = toRaw(ManageUsersw.value)
                            console.log(ManageUsers.value);
                            loading.value = false
                        }).catch(error => {
                            console.log(error);
                            ElMessage({
                                message: '请重新登录',
                                type: 'error',
                            })
                            router.push('./')
                        })
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
const edits = ref([])
const editse = ref(null)
const genders = ref('')
const edit = (wxUserId) => {
    // loading.value = true
    fetch.value = wxUserId
    showModal.value = !showModal.value;
    instance.get(`/api/wxuser/queryWxUserId/${wxUserId}`)
        .then(response => {
            editse.value = response.data.data
            edits.value = toRaw(editse.value)
            console.log(edits.value);
            form.value.name = edits.value.nickName
            genders.value = edits.value.gender
            console.log(genders.value);
            if (genders.value == 1) {
                form.value.gender = '男'
            }
            if (genders.value == 2) {
                form.value.gender = '女'
            }
            if (genders.value == 0 || genders.value == null) {
                form.value.gender = '未知'
            }
            loading.value = false
            console.log(form.value.gender);
        }).catch(error => {
            console.log(error);
        })

}
const onSubmits = () => {
    console.log(fetch.value);
    if (form.value.name === '') {
        ElMessage({
            showClose: true,
            message: '用户名不能为空',
            type: 'error',
        })
        return;
    }
    if (form.value.gender === '') {
        ElMessage({
            showClose: true,
            message: '性别不能为空',
            type: 'error',
        })
        return;
    }
    const gendere = ref('')
    console.log(form.value.gender);

    const edits = {
        'nickName': form.value.name,
        'gender': form.value.gender,
        'wxUserId': fetch.value
    }
    console.log(form.value.name, form.value.gender, fetch.value);
    console.log(edits);
    instance.put('/api/UserControl/updateWxUser', edits)
        .then(response => {
            ElMessage({
                showClose: true,
                message: '编辑成功',
                type: 'success',
            })
            instance.get('/api/wxuser/getAllUser')
                .then(response => {
                    tatol.value = response.data.data.length
                    console.log(tatol.value);
                })
                .catch(error => {
                    console.log(error);
                    console.error(error);
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                })
            instance.get(`/api/wxuser/paginationWxUser?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    ManageUsersw.value = response.data
                    ManageUsers.value = toRaw(ManageUsersw.value)
                    console.log(ManageUsers.value);
                    loading.value = false
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                })
            // window.location.reload()
        }).catch(error => {
            ElMessage({
                showClose: true,
                message: '编辑成功',
                type: 'error',
            })
        })
}
const feedbackesswQuery = () => {
    loading.value = true
    console.log(feedinput.value);
    if (feedinput.value === '') {
        instance.get('/api/wxuser/getAllUser')
            .then(response => {
                ManageUsersw.value = response.data.data
                ManageUsers.value = toRaw(ManageUsersw.value)
                console.log(ManageUsers.value);
                loading.value = false
            })
            .catch(error => {
                console.log(error);
            })
    } else {
        instance.get('/api/wxuser/getAllUser')
            .then(response => {
                const matchedUsers = [];
                ManageUsersw.value = response.data.data;

                for (let i = 0; i < ManageUsersw.value.length; i++) {
                    if (ManageUsersw.value[i].wxUserId === feedinput.value) {
                        matchedUsers.push(ManageUsersw.value[i]);
                    }
                }

                if (matchedUsers.length > 0) {
                    ManageUsers.value = matchedUsers;
                } else {
                    console.log('No matched users found.');
                }

                loading.value = false;
            })
            .catch(error => {
                console.log(error);
            });
    }
}
const page = reactive({ pageNum: 1, pageSize: 10 });
const handleSizeChanges = (val) => {
    page.pageSize = val;
    console.log(val);
    sifts();
};
//当前页改变时触发
const handleCurrentChanges = (val) => {
    page.pageNum = val;
    console.log(val);
    sifts();
};
const sifts = () => {
    loading.value = true
    console.log(page.pageSize);
    console.log(page.pageNum);
    instance.get(`/api/wxuser/paginationWxUser?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            ManageUsersw.value = response.data
            ManageUsers.value = toRaw(ManageUsersw.value)
            console.log(ManageUsers.value);
            loading.value = false
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.feedbackessw {
    position: fixed;
    width: 85vw;
    top: 10vh;
    left: 11vw;
}

.limit .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.limit .modal-contents {
    background-color: #fefefe;
    margin: 15% 15% 15% 35%;
    padding: 20px;
    border: 1px solid #888;
    width: 40vw;
    /* height: 100vh; */
    left: 15vw;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 14px;
    font-weight: bold;
}

.limit {
    width: 40vw;
    height: 40vh;

}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.el-table__inner-wrapper::before {
    height: 0px !important;
}

.clos {
    font-size: 14px;
}

.inpus {
    width: 15vw;
}

.feedbackesswbody {
    margin-top: 3vh;
    width: 100vw;
    /* height: 40vh; */
}

.feedbackesswfoot {
    width: 100vw;
    height: 50vh;
}

.feedbackesswhead {
    margin-top: -2vh;
}

.feedbackesswSearch {
    /* top: 3vh; */
    width: 35vh;
    left: 1vw;
}

.feedbackesswSearchBtn {
    /* margin-top: 5vh; */
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.feedbackesswfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    top: 95vh;
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}
</style>