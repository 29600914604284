import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import Menu from '../navbar/Menu.vue'
import Index from '../index/Index.vue'
import StoreManagement from '../stores/StoreManagement.vue'
import Commodity from '../commodity/Commodity.vue'
import Classify from '../commodity/Classify.vue'
import AllUsers from '../users/AllUsers.vue'
import ManageUsers from '../users/ManageUsers.vue'
import MemberUsers from '../users/MemberUsers.vue'
import SearchHistory from '../users/SearchHistory.vue'
import AllOrder from '../orders/AllOrder.vue'
import Order from '../orders/Order.vue'
import AnnouncementList from '../popularize/AnnouncementList.vue'
import AddAnnouncement from '../popularize/AddAnnouncement.vue'
import Ad from '../popularize/Ad.vue'
import Admin from '../administration/Admin.vue'
import Operate from '../administration/Operate.vue'
import Permissions from '../administration/Permissions.vue'
import Login from '../login/Login.vue'
import Profile from '../profile/Profile.vue'
import SetUp from '../login/SetUp.vue'
import AddStore from '../stores/AddStore.vue'
import ReviseStore from '../stores/ReviseStore.vue'
import UpdaStore from '../stores/UpdaStore.vue'
import Feedback from '../users/Feedback.vue'
import ProductDetails from '../commodity/ProductDetails.vue'
import Stava from '../administration/Stava.vue'
import Sta from '../administration/Sta.vue'
import ChangeThePassword from '../administration/ChangeThePassword'
import AddAnAccount from '../administration/AddAnAccount'
import AllProducts from '../commodity/AllProducts.vue'
import EditProduct from '../commodity/EditProduct.vue'
import Addsp from '../administration/Addsp.vue'
import EditVideo from '../administration/EditVideo.vue'
import Discount from '../views/promotion/discountCoupon.vue'
import Logistics from '../logistics/Logistics.vue'
import EditYourAd from '../popularize/EditYourAd.vue'
import Campus from '../campus/Campus.vue'
import Role from '../administration/Role.vue'
import UserRole from '../administration/UserRole.vue'
import EditAnnouncement from '../popularize/EditAnnouncement.vue'
import CapitalFlow from '../financialmanagement/CapitalFlow.vue'
import CommissionRecords from '../financialmanagement/CommissionRecords.vue'
import LnventoryManagement from '../Inventorymanagement/LnventoryManagement.vue'
import Comment from '../users/Comment.vue'
import HomePage from '../homepage/HomePage.vue'
import Withdrawal from '../withdrawal/Withdrawal.vue'
import Commission from '../withdrawal/Commission.vue' 
import RemoteAreas from '../RemoteAreas/RemoteAreas.vue'
import Carousel from '../administration/Carousel.vue'

import EditStatimg from '../administration/EditStatimg.vue'



//配置路由守卫../administration/Stat.vue
// import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/topnav',
    name: 'topnav',
    component: TopNav,
    meta: { requireAuth: true }
  },
  {
    path: '/menu',
    name: 'menu',
    component: Menu,
    meta: { requireAuth: true }
  },
  {
    path: '/leftnav',
    name: 'leftnav',
    component: LeftNav,
    children: [
      {
        path: '/index',
        name: 'index',
        component: Index,

      }, {
        path: '/classify',
        name: 'classify',
        component: Classify,
      }, {
        path: '/commodity',
        name: 'commodity',
        component: Commodity,
      },
      {
        path: '/manageusers',
        name: 'manageusers',
        component: ManageUsers,
      },
      {
        path: '/storemanagement',
        name: 'storemanagement',
        component: StoreManagement,
      }, {
        path: '/allusers',
        name: 'allusers',
        component: AllUsers,
      }, {
        path: '/memberusers',
        name: 'memberusers',
        component: MemberUsers,
      },
      {
        path: '/searchhistory',
        name: 'searchhistory',
        component: SearchHistory,
      },
      {
        path: '/allorder',
        name: 'allorder',
        component: AllOrder,
      }, {
        path: '/order',
        name: 'order',
        component: Order,
      }, {
        path: '/announcementlist',
        name: 'announcementlist',
        component: AnnouncementList,
      }, {
        path: '/addannouncement',
        name: 'addannouncement',
        component: AddAnnouncement,
      }, {
        path: '/ad',
        name: 'ad',
        component: Ad,
      }, {
        path: '/admin',
        name: 'admin',
        component: Admin,
      }, {
        path: '/operate',
        name: 'operate',
        component: Operate,
      }, {
        path: '/permissions',
        name: 'permissions',
        component: Permissions,
      }, {
        path: '/profile',
        name: 'profile',
        component: Profile,
      }, {
        path: '/setup',
        name: 'setup',
        component: SetUp,
      }, {
        path: '/addstore',
        name: 'addstore',
        component: AddStore,
      }, {
        path: '/revisestore',
        name: 'revisestore',
        component: ReviseStore,
      },
      {
        path: '/updastore',
        name: 'updastore',
        component: UpdaStore,
      },

      {
        path: '/feedback',
        name: 'feedback',
        component: Feedback,
      },
      {
        path: '/productdetails',
        name: 'productdetails',
        component: ProductDetails,
      },
      {
        path: '/stava',
        name: 'stava',
        component: Stava,
      },
      {
        path: '/sta',
        name: 'sta',
        component: Sta,
      },
      {
        path: '/changethepassword',
        name: 'changethepassword',
        component: ChangeThePassword,
      },
      {
        path: '/addanaccount',
        name: 'addanaccount',
        component: AddAnAccount,
      },
      {
        path: '/allproducts',
        name: 'allproducts',
        component: AllProducts,
      },
      {
        path: '/editproduct',
        name: 'editproduct',
        component: EditProduct,
      },
      {
        path: '/addsp',
        name: 'addsp',
        component: Addsp,
      },
      {
        path: '/editvideo',
        name: 'editvideo',
        component: EditVideo,
      },
      {
        path: '/discount',
        name: 'discount',
        component: Discount,
      },
      {
        path: '/logistics',
        name: 'logistics',
        component: Logistics,
      },
      {
        path: '/edityourad',
        name: 'edityourad',
        component: EditYourAd,
      },
      {
        path: '/campus',
        name: 'campus',
        component: Campus,
      },
      {
        path: '/role',
        name: 'role',
        component: Role,
      },
      {
        path: '/userrole',
        name: 'userrole',
        component: UserRole,
      }, {
        path: '/editannouncement',
        name: 'editannouncement',
        component: EditAnnouncement,
      },
      {
        path: '/commissionrecords',
        name: 'cmmissionrecords',
        component: CommissionRecords,
      },
      {
        path: '/capitalflow',
        name: 'capitalflow',
        component: CapitalFlow,
      },
      {
        path: '/lnventorymanagement',
        name: 'lnventorymanagement',
        component: LnventoryManagement,
      },
      {
        path: '/comment',
        name: 'comment',
        component: Comment,
      },
      {
        path: '/homepage',
        name: 'homepage',
        component: HomePage,
      },
      {
        path: '/withdrawal',
        name: 'withdrawal',
        component: Withdrawal,
      },
      {
        path: '/commission',
        name: 'commission',
        component: Commission,
      },
      {
        path: '/remoteareas',
        name: 'remoteareas',
        component: RemoteAreas,
      },
      {
        path: '/carousel',
        name: 'carousel',
        component: Carousel,
      },
      {
        path: '/editstatimg',
        name: 'editstatimg',
        component: EditStatimg,
      },

    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requireAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// //全局路由守卫
// router.beforeEach((to, from, next) => {
//   //如果路由需要跳转
//   if (to.path === "/") return next();
//   const tokenStr = window.sessionStorage.getItem("token");
//   if (!tokenStr) return next("/");
//   next();
// });

router.beforeEach((to, from, next) => {
  const tokenStr = window.localStorage.getItem("token");
  const isLoggedIn = !!tokenStr; // 双感叹号 !! 将变量转换为布尔值
  console.log(to.name)
  if (isLoggedIn && to.name === 'login') {
    return next('/index');
  }
  if (to.path === "/") return next();
  if (!tokenStr){
    return next("/");
  } 
  next(); // 放行
});
export default router
